<footer id="footer" class="footer">
    <div class="flex__container">
        <div class="footer-section">
            <h2>Contact us</h2>
            <a href="mailto:hello@ng-girls.org" target="_blank">hello@ng-girls.org</a>
        </div>
        <div class="footer-section">
            <h2>Code of Conduct</h2>
            <a href="./coc/">CoC</a>
        </div>
        <div class="footer-section logo-section">
            <app-svg [class]="'logo'" [icon]="'logo'" [size]="sizeLogo"></app-svg>
        </div>
    </div>
    <div class="flex__container">
        <div class="footer-section">
            <p class="copyright">
                &copy; 2016-{{currentDate}} Based on the theme <a href="https://github.com/gdg-x/zeppelin" target="_blank" rel="noopener">Project
                    Zeppelin</a> & a  <a href="https://scully.io/" target="_blank" rel="noopener">Scully</a> / <a href="https://angular.io/" target="_blank" rel="noopener">Angular</a> Jamstack<br />
                Created by <a href="https://www.behance.net/MartinaCarta/" target="_blank" rel="noopener">Martina Carta</a>,
                <a href="https://twitter.com/revitalfriedman" target="_blank" rel="noopener">Revital Friedman</a>,
                <a href="https://twitter.com/Ankita__Singhal" target="_blank" rel="noopener">Ankita Singhal</a>,
                <a href="https://twitter.com/robertSPD" target="_blank" rel="noopener">Robert Willemelis</a> &
                <a href="https://twitter.com/domizajac" target="_blank" rel="noopener">Dominika Zając</a>
            </p>
        </div>
    </div>
</footer>
<header id="top-header" class="top-header">
  <div class="overlay white-solid"></div>
  <a [routerLink] = "['/']" fragment="{{isHome == true ? 'home' : ''}}"  id="logo-header" class="logo-header" aria-label="Home of ngGirls">
    <app-svg [class]="'logo logo--header'" [icon]="'logo'" [size]="sizeLogo"></app-svg>
  </a>
  <nav class="st-menu st-effect" id="menu">
    <div class="logo-navbar logo logo-dark visible-xs"></div>
    <ul class="links-container">
      <li *ngFor="let link of navLinks">
        <!---TODO: if is home nofollow-->
        <a (click)="scrollTo(link.link)" rel="noopener" class="current" href="{{link.link}}" *ngIf="link.scroll == true && isHome == true">{{link.name}}</a>
        <a rel="noopener" class="current" href="{{link.link}}" *ngIf="link.scroll == false" >{{link.name}}</a>
        <a rel="noopener" class="current" href="{{baseUrl}}{{link.link}}" *ngIf="link.scroll == true && isHome == false" >{{link.name}}</a>
        <!-- <a class="current" href="{{link.link}}" >{{link.name}}<span *ngIf="isHome">|</span></a> -->
      </li>
    </ul>
  </nav>
</header>


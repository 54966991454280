<app-hero-section 
  [image]="page$.bg"
  [logo]="page$.logo"
  >
  <div class="button-div" *ngIf="(events$ | async) as events">
    <app-button [anchor]="'#workshops'" [rel]="'nofollow'" [label]="page$.heroButtonLabel" [type]="'anchor'"></app-button>
  </div>
</app-hero-section>
<app-preview 
  [title]="'Upcoming events:'"
  [itemsLimit]="3"
  slug="workshops"
>
</app-preview>


<app-patreon></app-patreon>

<app-about-section></app-about-section>

<app-team-section 
  [team]="organizers" 
  [title]="'Organizing Team'"
  [showPopups]="true"
  [image]="{ src: DEFAULTS.teamBg, alt: 'bg team', device: device}"
>

</app-team-section>

<app-partners-section 
  [partners]="partners">
</app-partners-section>

<app-preview 
  [title]="'Articles'"  
  [itemsLimit]="3"
  slug="blog"
  reverse="true"
  
  >
</app-preview>